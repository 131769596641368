
// import body font
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-display: optional;
	font-weight: $font-normal;
	src: local(''),
			url('../../../fonts/jost-400.woff2') format('woff2'),
			url('../../../fonts/jost-400.woff') format('woff');
}

// Body text
body {
	color: $color-text;
	font-family: $font-family-body;
	font-size: $font-size-body;
	font-weight: $font-normal;
}
