
//==========  Import modules  ==========//

// import our global modules
@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

// import our local modules
@import '../modules/local/typography/_index';


//==========  Begin styles  ==========//


// Reset
.cms-toolbar {
	@extend .hidden;
}

.mobile-ui {
	@extend .hidden;
}


// Print styles
* {
	color: $color-black;
	background: none;
}

.wrapper {
	padding: 2rem;
}

.header {
	@extend .hidden;
}

.hero {

	.inner {

		* {
			@extend .hidden;
		}

		.profile-image-wrapper,
		.profile-image {
			display: block;
		}

		.profile-image-wrapper {
			margin-bottom: 3rem;
		}
	}
}

.column {

	&.right,
	&.left {
		@extend .hidden;
	}
}

.crumbtrails,
.pre-footer {
	@extend .hidden;
}

.search-container {
	@extend .hidden;
}

.footer {
	@extend .hidden;
}

.cookies {
	@extend .hidden;
}

#ccc-notify {
	display: none!important;
}
