
// import heading font
@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-display: optional;
	font-weight: $font-mid;
	src: local(''),
			url('../../../fonts/jost-600.woff2') format('woff2'),
			url('../../../fonts/jost-600.woff') format('woff');
}

@font-face {
	font-family: 'Jost';
	font-style: normal;
	font-display: optional;
	font-weight: $font-bold;
	src: local(''),
		url('../../../fonts/jost-700.woff2') format('woff2'),
		url('../../../fonts/jost-700.woff') format('woff');
}

@font-face {
	font-family: 'Raleway';
	font-style: normal;
	font-display: optional;
	font-weight: $font-mid;
	src: local(''),
		url('../../../fonts/raleway-600.woff2') format('woff2'),
		url('../../../fonts/raleway-600.woff') format('woff');
}

// Headings
h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
	margin: 0 0 $heading-margin;
	color: $color-title;
	font-family: $font-family-title;
	font-weight: $font-mid;
	line-height: 1.3;
}

// h1, h2, .h1, .h2 {
// 	font-weight: $font-mid;
// }

h4, .h4 {
	color: $color-text;
}

h1,.h1 { font-size: $h1; }
h2,.h2 { font-size: $h2; }
h3,.h3 { font-size: $h3; }
h4,.h4 { font-size: $h4; }
h5,.h5 { font-size: $h5; }
h6,.h6 { font-size: $h6; }

h1,
.h1 {
	font-size: $h2;
	line-height: 1.1;

	@media #{$tabletXL} {
		font-size: $h1;
	}
}
