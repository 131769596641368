
// Clear paragraph styles
p {
	margin: 0;
}

// Reset strong tags
strong {
	font-weight: $font-bold;
}

// Reset hr tags
hr {
	border: $border;
}

.iframe-wrapper {
	padding-bottom: 56.25%;
	position: relative;
	margin-bottom: $block-margin;

	iframe {
		height: 100%;
		position: absolute;
		width: 100%;
	}
}

iframe {
	aspect-ratio: 16 / 9;
	width: 100%;
	border: 0;
}

em {
	color: $color-primary;
	font-style: normal;
	font-weight: $font-bold;
}


