
$color-border-intro: #a2aeb5;

// Content styles
.block-text,
.biography,
.office-directions {
	line-height: 1.7;

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		&.intro {
			padding: 0 0 $paragraph-margin;
			border-bottom: $border-size $border-style $color-border-intro;
			color: $color-text;
			font-family: $font-family-sub;
			font-size: $h4;
			line-height: 1.7;
		}
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;

		ul {
			@extend %reset-ul;
		}
	}

	// New default <ul> lists
	ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	ol {
		@extend %default-ol;
	}

	a {

		&:not([class]) {
			border-bottom: ($border-size + 1) $border-style $color-secondary;
		}
	}

	.book-image {
		display: block;
		max-width: 100%;
	}
}

.section-useful_reading {

	.content {
		ul {
			list-style: disc;
			@extend %default-list;
		}
	}

}

//embedded images
#the-workbook,
#mediation,
#the-book {

	.content {

		img {
			@extend %image;
			max-width: 300px;
		}

		.video-container {
			padding-bottom: 56.25%;
			position: relative;
			margin-bottom: $block-margin;

			@media #{$laptop} {
				padding-bottom: 40%;
				// width: 70%;
			}

			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}

}

