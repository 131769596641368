
// Default .button class
.button {
    @extend %button;
    @extend %button-primary;

    &:hover {
        @extend %hover;
    }

    &-secondary {
        @extend %button;
        @extend %button-secondary;

        &:hover {
            @extend %hover-secondary;
        }
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
